import React, { Component, ClipboardEvent } from "react";
import { RouteComponentProps } from "react-router";
import { TextField, Button } from "@material-ui/core";
import "./AwaitingVerification.scss";
import { UserHelper } from "../../../../Helpers/UserHelper";
import { getCurrentUser, logUserOut } from "../../../../Helpers/SessionHelper";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps extends RouteComponentProps { }
interface IState {
  currentState: 'email' | 'sms' | 'loading' | 'success';
  phone: string;
  email: string;
  profileType: 'freelancer' | 'company';
  field: { one: string, two: string, three: string, four: string, five: string, [key: string]: string }
}

export class AwaitingVerificaton extends Component<IProps, IState> {
  one: any;
  two: any;
  three: any;
  four: any;
  five: any;

  userHelper = new UserHelper();

  constructor(props: IProps) {
    super(props);
    this.state = {
      currentState: 'sms',
      phone: '',
      email: '',
      profileType: 'freelancer',
      field: { one: '', two: '', three: '', four: '', five: '' },
    }
    this.one = React.createRef();
    this.two = React.createRef();
    this.three = React.createRef();
    this.four = React.createRef();
    this.five = React.createRef();

    this.handleCode = this.handleCode.bind(this);
    this.sendActivateMail = this.sendActivateMail.bind(this);
    this.onPaste = this.onPaste.bind(this);
  }

  componentDidMount() {
    let user = getCurrentUser();
    if (user == null) {
      this.props.history.push('/login');
      return;
    }
    let phone = user.LoginPhone;

    if (!phone) {
      return this.setState({ currentState: 'email', email: user.Email });
    }

    this.setState({ phone, currentState: 'sms', email: user.Email });
  }


  onPaste(event: ClipboardEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
    let data = event.clipboardData.getData('text');
    if (typeof (data) !== 'string' || data === '' || data.length !== 5) return;

    this.setState({
      field: {
        one: data[0],
        two: data[1],
        three: data[2],
        four: data[3],
        five: data[4]
      }
    });

    this.submitCode();
    this.setState({ currentState: 'loading' });
  }


  async handleCode(e: any) {
    let value = (e.target.value as string).replace(/[^0-9 ]/g, '');
    let name = e.target.name;
    if (value !== e.target.value) return;


    let selected = e.target.name;
    // Sets the targeted field to contain the value
    let field = this.state.field;
    if (value.length > 1) {
      field[selected] = value.replace(field[selected], '');
    } else {
      field[selected] = value;
    }
    await this.setState({ field });

    if (value.length === 0) return;
    switch (name) {
      case 'one':
        this.two.current.focus();
        break;
      case 'two':
        this.three.current.focus();
        break;
      case 'three':
        this.four.current.focus();
        break;
      case 'four':
        this.five.current.focus();
        break;
      case 'five':
        this.five.current.blur();
        break;
    }


    // Check if the entire password has been written
    let isFull = true;
    for (const key in this.state.field) {
      if (this.state.field.hasOwnProperty(key)) {
        const element = this.state.field[key];
        if (element.length === 0) {
          isFull = false
          break;
        }
      }
    }

    // If it has, we check if it's valid
    if (isFull) {
      this.submitCode();
      this.setState({ currentState: 'loading' });
    }
  }

  async submitCode() {
    let field = this.state.field;
    let code = '';
    for (const key in field) {
      if (field.hasOwnProperty(key)) {
        const element = field[key];
        code += element;
      }
    }
    let response = await this.userHelper.activateWithCode(code);
    if (response.result) {
      logUserOut();
      this.props.history.push('/login');
    } else {

      let message = '';
      if (response.response == null) {
        message = getLanguage(469, 'Unknown error');
      } else if (typeof response.response === 'string') {
        message = response.response;
      } else {
        message = response.response.Message;
      }

      SnackbarManager.Instance.addError(message);
      this.setState({
        currentState: 'sms',
        field: { one: '', two: '', three: '', four: '', five: '' }
      });
    }
  }

  async sendActivateMail() {

    // this.state.
    let response = await this.userHelper.sendActivationMail();
    if (response.result) {
      SnackbarManager.Instance.addSuccess(getLanguage(674, "Email sent"));
      this.setState({ currentState: 'email' });
    } else {
      SnackbarManager.Instance.addError(getLanguage(675, 'Could not send email, please try again later or contact support'));
    }
  }

  renderViaCode() {
    return (
      <div id="verifyViaCode" >
        <h1>{getLanguage(568, "Please verify your account")}</h1>
        <p>
          {getLanguage(676, 'Weve sent an activation code to {{phone}}.').replace("{{phone}}", this.state.phone)}<br />
          {getLanguage(677, "Please verify your account")}
        </p>
        <div className="verificationWrapper padding-bottom-24">
          <TextField
            id="digit-1"
            name='one'
            autoFocus
            placeholder="X"
            margin="normal"
            variant="outlined"
            className="verifyInput"
            onChange={this.handleCode}
            value={this.state.field.one}
            inputRef={this.one}
            onPaste={this.onPaste}
            type="number"   // This makes sure it also works on desktop
            inputProps={{
              inputMode: 'numeric',
            }}
          />
          <TextField
            id="digit-2"
            name='two'
            placeholder="X"
            margin="normal"
            variant="outlined"
            className="verifyInput"
            onChange={this.handleCode}
            value={this.state.field.two}
            inputRef={this.two}
            onPaste={this.onPaste}
            type="number"   // This makes sure it also works on desktop
            inputProps={{
              inputMode: 'numeric',
            }}
          />
          <TextField
            id="digit-3"
            name='three'
            placeholder="X"
            margin="normal"
            variant="outlined"
            className="verifyInput"
            onChange={this.handleCode}
            value={this.state.field.three}
            inputRef={this.three}
            onPaste={this.onPaste}
            type="number"   // This makes sure it also works on desktop
            inputProps={{
              inputMode: 'numeric',
            }}
          />
          <TextField
            id="digit-4"
            name='four'
            placeholder="X"
            margin="normal"
            variant="outlined"
            className="verifyInput"
            onChange={this.handleCode}
            value={this.state.field.four}
            inputRef={this.four}
            onPaste={this.onPaste}
            type="number"   // This makes sure it also works on desktop
            inputProps={{
              inputMode: 'numeric',
            }}
          />
          <TextField
            id="digit-5"
            name='five'
            placeholder="X"
            margin="normal"
            variant="outlined"
            className="verifyInput"
            onChange={this.handleCode}
            value={this.state.field.five}
            inputRef={this.five}
            onPaste={this.onPaste}
            type="number"   // This makes sure it also works on desktop
            inputProps={{
              inputMode: 'numeric',
            }}
          />
        </div>
        <div className="bottomLink">
          <p>
            {getLanguage(678, "Didnt receive any code?")}

            <Button size="small" variant="text" color="primary" onClick={() => this.sendActivateMail()}>
              {getLanguage(679, "Verify account via your email.")}
            </Button>
          </p>
        </div>
      </div>
    );
  }

  renderViaEmail() {
    return (
      <div id="verifyViaMail">
        <div className="relative">
          <h1>{getLanguage(568, "Please verify your account")}</h1>
          <p>
            {getLanguage(680, 'Weve sent an activation mail to {{email}}.').replace("{{email}}", this.state.email)}<br />
            {getLanguage(681, "Please click the link in that mail to activate your account.")}</p>
        </div>
        <div className="bottomLink">
          <p>
            {getLanguage(569, "Didn't receive any mail?")}
            <Button size="small" variant="text" color="primary" onClick={() => this.sendActivateMail()}>
              {getLanguage(570, "Resend email")}
            </Button>
          </p>
        </div>
      </div>);
  }

  renderLoading() {
    return (
      <div id="verifiedSuccess" >
        <p>{getLanguage(571, "Please wait")}</p>
        <i className="fas fa-spinner fa-spin"></i>
      </div>);
  }

  renderSuccess() {
    return (
      <div id="verifiedSuccess" >
        <i className="fas fa-check-circle fa-3x success"></i>
        <h1>{getLanguage(572, "Account verified")}</h1>
      </div>);
  }

  renderBody() {
    switch (this.state.currentState) {
      case 'email':
        return this.renderViaEmail();
      case 'sms':
        return this.renderViaCode()
      case 'loading':
        return this.renderLoading();
      case 'success':
        return this.renderSuccess();
    }
  }


  render() {
    return (
      <div>
        <div className="container text-center">
          <div className="vh-100 flex-center-center">
            <div className="block">
              {this.renderBody()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
