import { AxiosRequestConfig } from 'axios';
import { StorageHelper } from './StorageHelper';
import { CallManager } from './CallManager/CallManager';
import { OvertimeRequestModel } from '../Models/OvertimeRequestModel';
import { RatingModel } from '../Models/RatingModel';
import { LateCancelModel } from '../Models/LateCancelModel';
import { RequestModel } from '../Models/RequestModel';
import * as Sentry from "@sentry/react";




export class RequestHelper {
  storageHelper = new StorageHelper();
  clientKey = '04216571796E48ABB8FD781DEA966A4F';


  async getAllRequestsForPosting(postingId: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();


      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/request/posting/' + postingId,
        headers: {
          'Content-Type': 'application/json',
          clientKey: this.clientKey,
          authToken
        }
      }

      let response = await CallManager.Instance.makeCall(config, true, false);

      if (response)
        return response.data;

      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async cancelRequest(requestId: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/request/' + requestId + '/cancel',
        headers: {
          'Content-Type': 'application/json',
          clientKey: this.clientKey,
          authToken
        }
      }
      Sentry.captureMessage(`Request ${requestId} cancel triggered`, 'debug');
      let response = await CallManager.Instance.makeCall(config, true, false);
      Sentry.captureMessage(`Request ${requestId} cancel status = ${response.status} and response ${response.data} `, 'debug');

      if (response)
        return response.data;

      return false;
    } catch (error: any) {
      Sentry.captureException(error);
      return false;
    }
  }


  async applyForPosting(postingId: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/request',
        headers: {
          'Content-Type': 'application/json',
          clientKey: this.clientKey,
          authToken
        },
        data: {
          PostingId: postingId,
          SignatureAsBase64: ''
        }
      }
      let response = await CallManager.Instance.makeCall(config, true, false);

      if (response)
        return response.data;

      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async getAllRequestsForFreelancer() {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/request/getallforfreelancer',
        headers: {
          'Content-Type': 'application/json',
          clientKey: this.clientKey,
          authToken
        },
      }
      let response = await CallManager.Instance.makeCall(config, true, false);
      return response.data;
    } catch (error: any) {
      return [];
    }
  }

  async getFirstConfirmed() {
    try {
      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/request/firstconfirmed',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }

      let response = await CallManager.Instance.makeCall(config, false, false);

      return response.data;
    } catch (error: any) {
      console.log('error', error.response);
      return false;
    }
  }

  async getOvertimeRequests() {
    try {

      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/overtimerequest',
        headers: {
          'Content-Type': 'application/json',
          clientKey: this.clientKey,
        }
      }

      let response = await CallManager.Instance.makeCall(config, true);

      if (response.status === 200) {
        return response.data.map((x: OvertimeRequestModel) => new OvertimeRequestModel(x));
      }
      return []
    } catch (error: any) {
      return [];
    }
  }

  async getRatingsById(id: string): Promise<RatingModel[]> {
    try {
      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/rating/get/' + id,
        headers: {
          'Content-Type': 'application/json',
          clientKey: this.clientKey,
        },
      }

      let response = await CallManager.Instance.makeCall(config, true);

      if (response.status === 200) {
        return response.data.map((x: RatingModel) => new RatingModel(x));
      }
      return []
    } catch (error: any) {
      return [];
    }
  }

  async getCompletedForFreelancer(freelancerId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/request/completedforfreelancer/',
        params: { freelancerId },
      }
      let response = await CallManager.Instance.makeCall(config, false, false);

      return response.data;
    } catch (error: any) {
      console.log('error', error);
      return [];
    }
  }

  async getCancelledByFreelancerId(freelancerId: string): Promise<LateCancelModel[]> {
    try {
      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/request/cancellationsbyfreelancerid',
        params: { freelancerId }
      }
      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data;
    } catch (error: any) {
      console.log('error', error);
      return [];
    }
  }

  async getFutureRequests() {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/request/future/',
        withCredentials: true,
        headers: {
          "Content-type": "application/json",
          authToken: new StorageHelper().getAccessToken(),
        },
      }
      let response = await CallManager.Instance.makeCall(config, false);

      if (response.data.length)
        return response.data as RequestModel[];

      return [];
    } catch (error: any) {
      console.log(error);
      return [];
    }
  }

  async getCostForRequests(requestIds: string[]) {
    try {
      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/request/cost',
        headers: {
          "Content-type": "application/json",
          authToken: new StorageHelper().getAccessToken(),
        },
        data: {
          requestIds: requestIds,
        }
      }
      let response = await CallManager.Instance.makeCall(config, false);

      if (response)
        return response.data;

      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }
}